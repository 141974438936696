import {
  ControllerParams,
  CreateControllerFn,
  IWixAPI,
} from '@wix/yoshi-flow-editor';
import { FAQService } from '../../utils/FAQService';
import { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { ISettingsGetter } from '@wix/tpa-settings';
import settingsParams from './settingsParams';
import constants from '../../constants';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

const asyncFun = async (settings: { get: any }, api: FAQService,qcategoryId: string | null | undefined) => {
  let selectedCategory;
  let questionEntries: QuestionEntry[] | null;
  const { showAll, categories } = settings.get(settingsParams.manageCategories);
  const { categories: categriesList } = await api.getListCategories();

  const oneListOfQuestions =
    settings.get(settingsParams.displayContent) ===
    constants.DISPLAY_CONTENT.ONE_LIST_OF_QUESTIONS;
  let firstId: string | undefined | null = qcategoryId ?? undefined;
  const validLists = categriesList?.filter((category: { id?: any }) => {
    const categoryId = category?.id;
    if (showAll) {
      if (typeof firstId === 'undefined') {
        firstId = categoryId;
      }
      return true;
    }
    if (!categoryId) {
      return false;
    }
    const isValid = categories?.includes(categoryId);
    if (isValid) {
      if (typeof firstId === 'undefined') {
        firstId = categoryId;
      }
      return true;
    }
  });
  const validCategories = validLists
    ?.filter((category: Category) => category?.id)
    .map((category: Category) => category?.id);
  if (oneListOfQuestions) {
    selectedCategory = validCategories;
  } else if (firstId) {
    selectedCategory = firstId;
  }
  const result = await api.getListQuestions({
    categoryId: selectedCategory,
  });
  if (result.questionEntries) {
    questionEntries = result.questionEntries;
  } else {
    questionEntries = [];
  }
  return { selectedCategory, validCategories, questionEntries, validLists };
};
const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}: ControllerParams) => {
  function getUuidQuestionIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]questionId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/,
    );
    return match ? match[1] : null;
  }
  function getAppDefIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]appDefId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/i,
    );
    return match ? match[1] : null;
  }

  const getQuestionId = async (api: FAQService) => {
    const appID = controllerConfig.appParams.appDefinitionId;
    const url = controllerConfig.wixCodeApi.location.url;
    const appDefId = getAppDefIdFromUrl(url);
    const isFAQAppDefId = appDefId === appID;
    const questionId = isFAQAppDefId ? getUuidQuestionIdFromUrl(url) : null;
    if (!questionId) {
      return {
        categoryId: null,
        questionId: null,
      };
    }
    const result = await api.getQuestion({ id: questionId });
    console.log(result)
    return {
      categoryId: result.questionEntries?.[0].categoryId,
      questionId: result.questionEntries?.[0].id,
    };
  };

  const getInitialData = async (
    settings: { get: ISettingsGetter },
    faqService: FAQService,
    categoryId: string | null | undefined,
  ) => {
    let result;
    try {
      result = await asyncFun(settings, faqService,categoryId);
    } catch (e) {
      console.error(e);
      result = {

        validLists: null,
        selectedCategory: null,
        validCategories: null,
        questionEntries: null,
      };
    }
    return result;
  };

  return {
    async pageReady() {
      const instance = controllerConfig.appParams.instance;
      const baseURL = controllerConfig.wixCodeApi.location.baseUrl;
      const api = new FAQService(instance);

      const { questionId, categoryId } = await getQuestionId(api);
      const initialData = await getInitialData(
        flowAPI.settings,
        api,
        categoryId,
      );

      controllerConfig.setProps({
        instance,
        baseURL,
        questionId,
        initialData,
        api: {
          getListCategories: api.getListCategories,
          getListQuestions: api.getListQuestions,
        },
      });
    },
  };
};

export default createController;
